@import '../mixins';
@import '../color-variables';

.loadingBubble {
  box-sizing: border-box;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
  margin-left: 20px;
  height: 30px;
  .spinner {
    position: static !important;
    margin-top: -11px;
    margin-left: 0px;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 45px;
    height: 6px;

    margin-left: -22px;
    margin-top: -22px;

    text-align: center;
    div {
      background-color: #fff;
    }
  }

  -webkit-animation: message-bounce 2.5s infinite ease-in-out;
  animation: message-bounce 2.5s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  .spinner > div {
    width: 6px;
    height: 6px;
    background-color: #dcdcdc;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1400ms ease-in-out infinite;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@keyframes message-bounce {
  0% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
}

@include keyframes(bouncedelay) {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
