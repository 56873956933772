@import '../mixins';
@import '../color-variables';

.spanFile {
  background-color: $primary;
  padding: 2px 4px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
