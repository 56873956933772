//Horizon UI color variables
$brand-100: #e9e3ff;
$brand-200: #422afb;
$brand-300: #422afb;
$brand-400: #7551ff;
$brand-500: #422afb;
$brand-600: #3311db;
$brand-700: #02044a;
$brand-800: #190793;
$brand-900: #11047a;

$brandScheme-100: #e9e3ff;
$brandScheme-200: #7551ff;
$brandScheme-300: #7551ff;
$brandScheme-400: #7551ff;
$brandScheme-500: #422afb;
$brandScheme-600: #3311db;
$brandScheme-700: #02044a;
$brandScheme-800: #190793;
$brandScheme-900: #02044a;

$brandTabs-100: #e9e3ff;
$brandTabs-200: #422afb;
$brandTabs-300: #422afb;
$brandTabs-400: #422afb;
$brandTabs-500: #422afb;
$brandTabs-600: #3311db;
$brandTabs-700: #02044a;
$brandTabs-800: #190793;
$brandTabs-900: #02044a;

$secondaryGray-100: #e0e5f2;
$secondaryGray-200: #e1e9f8;
$secondaryGray-300: #f4f7fe;
$secondaryGray-400: #e9edf7;
$secondaryGray-500: #8f9bba;
$secondaryGray-600: #a3aed0;
$secondaryGray-700: #707eae;
$secondaryGray-800: #707eae;
$secondaryGray-900: #1b2559;

$red-100: #feefee;
$red-500: #ee5d50;
$red-600: #e31a1a;

$blue-50: #eff4fb;
$blue-500: #3965ff;

$orange-100: #fff6da;
$orange-500: #ffb547;

$green-100: #e6faf5;
$green-500: #01b574;

$navy-50: #d0dcfb;
$navy-100: #aac0fe;
$navy-200: #a3b9f8;
$navy-300: #728fea;
$navy-400: #3652ba;
$navy-500: #1b3bbb;
$navy-600: #24388a;
$navy-700: #1b254b;
$navy-800: #111c44;
$navy-900: #0b1437;

$gray-100: #fafcfe;

//Chakra color variables
$whiteAlpha-50: rgba(255, 255, 255, 0.04);
$whiteAlpha-100: rgba(255, 255, 255, 0.06);
$whiteAlpha-200: rgba(255, 255, 255, 0.08);
$whiteAlpha-300: rgba(255, 255, 255, 0.16);
$whiteAlpha-400: rgba(255, 255, 255, 0.24);
$whiteAlpha-500: rgba(255, 255, 255, 0.36);
$whiteAlpha-600: rgba(255, 255, 255, 0.48);
$whiteAlpha-700: rgba(255, 255, 255, 0.64);
$whiteAlpha-800: rgba(255, 255, 255, 0.8);
$whiteAlpha-900: rgba(255, 255, 255, 0.92);

$blackAlpha-50: rgba(0, 0, 0, 0.04);
$blackAlpha-100: rgba(0, 0, 0, 0.06);
$blackAlpha-200: rgba(0, 0, 0, 0.08);
$blackAlpha-300: rgba(0, 0, 0, 0.16);
$blackAlpha-400: rgba(0, 0, 0, 0.24);
$blackAlpha-500: rgba(0, 0, 0, 0.36);
$blackAlpha-600: rgba(0, 0, 0, 0.48);
$blackAlpha-700: rgba(0, 0, 0, 0.64);
$blackAlpha-800: rgba(0, 0, 0, 0.8);
$blackAlpha-900: rgba(0, 0, 0, 0.92);

$gray-50: #f7fafc;
$gray-100: #edf2f7;
$gray-200: #e2e8f0;
$gray-300: #cbd5e0;
$gray-400: #a0aec0;
$gray-500: #718096;
$gray-600: #4a5568;
$gray-700: #2d3748;
$gray-800: #1a202c;
$gray-900: #171923;

$red-50: #fff5f5;
$red-100: #fed7d7;
$red-200: #feb2b2;
$red-300: #fc8181;
$red-400: #f56565;
$red-500: #e53e3e;
$red-600: #c53030;
$red-700: #9b2c2c;
$red-800: #822727;
$red-900: #63171b;

$orange-50: #fffaf0;
$orange-100: #feebc8;
$orange-200: #fbd38d;
$orange-300: #f6ad55;
$orange-400: #ed8936;
$orange-500: #dd6b20;
$orange-600: #c05621;
$orange-700: #9c4221;
$orange-800: #7b341e;
$orange-900: #652b19;

$yellow-50: #fffff0;
$yellow-100: #fefcbf;
$yellow-200: #faf089;
$yellow-300: #f6e05e;
$yellow-400: #ecc94b;
$yellow-500: #d69e2e;
$yellow-600: #b7791f;
$yellow-700: #975a16;
$yellow-800: #744210;
$yellow-900: #5f370e;

$green-50: #f0fff4;
$green-100: #c6f6d5;
$green-200: #9ae6b4;
$green-300: #68d391;
$green-400: #48bb78;
$green-500: #38a169;
$green-600: #2f855a;
$green-700: #276749;
$green-800: #22543d;
$green-900: #1c4532;

$teal-50: #e6fffa;
$teal-100: #b2f5ea;
$teal-200: #81e6d9;
$teal-300: #4fd1c5;
$teal-400: #38b2ac;
$teal-500: #319795;
$teal-600: #2c7a7b;
$teal-700: #285e61;
$teal-800: #234e52;
$teal-900: #1d4044;

$blue-50: #ebf8ff;
$blue-100: #bee3f8;
$blue-200: #90cdf4;
$blue-300: #63b3ed;
$blue-400: #4299e1;
$blue-500: #3182ce;
$blue-600: #2b6cb0;
$blue-700: #2c5282;
$blue-800: #2a4365;
$blue-900: #1a365d;

$cyan-50: #edfdfd;
$cyan-100: #c4f1f9;
$cyan-200: #9decf9;
$cyan-300: #76e4f7;
$cyan-400: #0bc5ea;
$cyan-500: #00b5d8;
$cyan-600: #00a3c4;
$cyan-700: #0987a0;
$cyan-800: #086f83;
$cyan-900: #065666;

$purple-50: #faf5ff;
$purple-100: #e9d8fd;
$purple-200: #d6bcfa;
$purple-300: #b794f4;
$purple-400: #9f7aea;
$purple-500: #805ad5;
$purple-600: #6b46c1;
$purple-700: #553c9a;
$purple-800: #44337a;
$purple-900: #322659;

$pink-50: #fff5f7;
$pink-100: #fed7e2;
$pink-200: #fbb6ce;
$pink-300: #f687b3;
$pink-400: #ed64a6;
$pink-500: #d53f8c;
$pink-600: #b83280;
$pink-700: #97266d;
$pink-800: #702459;
$pink-900: #521b41;

$linkedin-50: #e8f4f9;
$linkedin-100: #cfedfb;
$linkedin-200: #9bdaf3;
$linkedin-300: #68c7ec;
$linkedin-400: #34b3e4;
$linkedin-500: #00a0dc;
$linkedin-600: #008cc9;
$linkedin-700: #0077b5;
$linkedin-800: #005e93;
$linkedin-900: #004471;

$facebook-50: #e8f4f9;
$facebook-100: #d9dee9;
$facebook-200: #b7c2da;
$facebook-300: #6482c0;
$facebook-400: #4267b2;
$facebook-500: #385898;
$facebook-600: #314e89;
$facebook-700: #29487d;
$facebook-800: #223b67;
$facebook-900: #1e355b;

$messenger-50: #d0e6ff;
$messenger-100: #b9daff;
$messenger-200: #a2cdff;
$messenger-300: #7ab8ff;
$messenger-400: #2e90ff;
$messenger-500: #0078ff;
$messenger-600: #0063d1;
$messenger-700: #0052ac;
$messenger-800: #003c7e;
$messenger-900: #002c5c;

$whatsapp-50: #dffeec;
$whatsapp-100: #b9f5d0;
$whatsapp-200: #90edb3;
$whatsapp-300: #65e495;
$whatsapp-400: #3cdd78;
$whatsapp-500: #22c35e;
$whatsapp-600: #179848;
$whatsapp-700: #0c6c33;
$whatsapp-800: #01421c;
$whatsapp-900: #001803;

$twitter-50: #e5f4fd;
$twitter-100: #c8e9fb;
$twitter-200: #a8dcfa;
$twitter-300: #83cdf7;
$twitter-400: #57bbf5;
$twitter-500: #1da1f2;
$twitter-600: #1a94da;
$twitter-700: #1681bf;
$twitter-800: #136b9e;
$twitter-900: #0d4d71;

$telegram-50: #e3f2f9;
$telegram-100: #c5e4f3;
$telegram-200: #a2d4ec;
$telegram-300: #7ac1e4;
$telegram-400: #47a9da;
$telegram-500: #0088cc;
$telegram-600: #007ab8;
$telegram-700: #006ba1;
$telegram-800: #005885;
$telegram-900: #003f5e;

$main: linear-gradient(268.85deg, #226cff 22.48%, #1084ff 83.59%);
$white: #ffffff;
$bg1: #103256;
$bg2: #082646;
$bg4: #274667;
$bg3: #ffffff;
$primary: #239eca;
$gray-1: #3d5976;
$text-main: #424242;
$border: #cbcbcb;
